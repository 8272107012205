import React from "react";
import { graphql } from "gatsby";
import cx from "classnames";

import theme from "../constants/theme";

import Layout from "../components/layout";
import SEO from "../components/seo";

import { SubHeader, ContactFormSection, CTASection } from "../components/blocks";

export default function contactPageTemplate(props) {
  const siteMetaData = props.data.site.siteMetadata;
  const siteData = props.data.allSanitySiteConfig.edges[0].node;
  const sharedData = props.data.allSanitySharedSections.edges[0].node;
  const pageData = props.data.allSanityContactPage.edges[0].node;

  return (
    <Layout
      locale={props.pageContext.locale}
      siteMetaData={siteMetaData}
      mainNav={siteData.mainNav}
      alternateNav={siteData.alternateNav}>
      <SEO title={pageData.pageTitle?.localized} />
      <SubHeader
        title={pageData.header?.title?.localized}
        subTitle={pageData.header?.description?.localized}
      />
      <ContactFormSection
        title={pageData.contactForm?.leadText?.title?.localized}
        descriptionRichText={pageData.contactForm?.leadText?.descriptionRichText ?? {}}
        formFields={pageData.contactForm?.fields ?? []}
        submitButtonTitle={pageData.contactForm?.submitButtonTitle?.localized}
        confirmation={{
          title: pageData.contactForm?.confirmationTitle?.localized,
          description: pageData.contactForm?.confirmationDescription?.localized,
          buttonTitle: pageData.contactForm?.confirmationButtonTitle?.localized,
        }}
      />
    </Layout>
  );
}

import * as _ from "./fragments";

export const query = graphql`
  query($locale: String) {
    # Site Meta Data
    site {
      ...SiteMetaData
    }
    # Site
    allSanitySiteConfig {
      edges {
        node {
          ...MainNav
          ...AlternateNav
        }
      }
    }
    # Shared
    allSanitySharedSections {
      edges {
        node {
          ...CallToActionSection
        }
      }
    }
    # Page
    allSanityContactPage {
      edges {
        node {
          pageTitle {
            localized(locale: $locale)
          }
          header {
            ...SubHeader
          }
          contactForm {
            leadText {
              ...LeadText
            }
            fields {
              element
              name
              label {
                localized(locale: $locale)
              }
              placeholder {
                localized(locale: $locale)
              }
            }
            submitButtonTitle {
              localized(locale: $locale)
            }
            confirmationTitle {
              localized(locale: $locale)
            }
            confirmationDescription {
              localized(locale: $locale)
            }
            confirmationButtonTitle {
              localized(locale: $locale)
            }
          }
        }
      }
    }
  }
`;
